import { defineStore } from "pinia"
import { updateSubQuery, clearSubQuery } from "@evercam/shared/utils"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useReportStore } from "@/stores/report"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { Project } from "@evercam/shared/types/project"
import axios from "axios"
import { useNuxtApp, useRuntimeConfig } from "#app"

interface NewProjectOwner {
  email: string
  fullname: string
  id: number
}

interface NewProject {
  cameras: string[]
  exid: string
  id: number
  insertedAt: Date | null
  name: string
  owner: NewProjectOwner
  updatedAt: Date | string
}

interface ProjectStore {
  editedProject: Project
  newProject: NewProject
  currentTab: number
  selectedProjectExid: string
  isDialogOpen: boolean
  tPulseLink: string
}

export const useProjectStore = defineStore({
  id: "project",
  state: (): ProjectStore => ({
    editedProject: {} as Project,
    newProject: {
      cameras: [],
      exid: "",
      id: 0,
      insertedAt: null,
      name: "",
      owner: {
        email: "construction@evercam.io",
        fullname: "Construction Evercam",
        id: 13959,
      },
      updatedAt: "",
    },
    currentTab: 0,
    selectedProjectExid: "",
    isDialogOpen: false,
    tPulseLink: null,
  }),
  actions: {
    async createProject(project) {
      try {
        await AdminApi.projects.createProject({
          ownerId: project.owner.id,
          projectName: project.name,
          cameras: project.cameras.map((c) => c.exid),
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          `The project "${project.name}" has been created successfully`
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `The project "${project.name}" could not be created!`,
          error,
        })
      }
    },
    async editProject(project) {
      try {
        await AdminApi.projects.updateProject(project.exid, {
          name: project.name,
          timezone: project.timezone,
          featureFlags: project.featureFlags,
          cameras: project.cameras?.map((c) => c.exid),
        })
        useNuxtApp().nuxt2Context.$notifications.success(
          `The project "${project.name}" has been edited successfully`
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `The project "${project?.name}" could not be edited!`,
          error,
        })
      }
    },
    async deleteSelectedProjects() {
      const reportStore = useReportStore()
      const selected = reportStore.selectedItems
      let IsDeletionFailed = false
      if (selected.length === 0) {
        useNuxtApp().nuxt2Context.$notifications.warn(
          "At least select one project!"
        )
      } else {
        if (
          await useNuxtApp().nuxt2Context.$confirmDialog.open({
            title: "Delete project(s) ?",
            message: "This will permanently delete selected project(s)!",
          })
        ) {
          for (const project of selected as Project[]) {
            try {
              await AdminApi.projects.deleteProject(project.exid)
              reportStore.items = reportStore.items.filter(
                (item: Project) => item.exid !== project.exid
              )
            } catch (error) {
              IsDeletionFailed = true
              useNuxtApp().nuxt2Context.$notifications.error({
                text: "Failed to delete selected project!",
                error,
              })
            }
          }
          reportStore.selectedItems = []
          if (!IsDeletionFailed) {
            useNuxtApp().nuxt2Context.$notifications.success(
              "Selected project(s) has been deleted successfully"
            )
          }
        }
      }
    },
    openDialog(projectExid) {
      useCameraDialogStore().dialog = false

      updateSubQuery({
        project_dialog: true,
        project_exid: projectExid,
      })
      this.currentTab = 0
      this.selectedProjectExid = projectExid
      this.isDialogOpen = true
    },
    closeDialog() {
      clearSubQuery()
      this.selectedProjectExid = ""
      this.isDialogOpen = false
    },
    async updateTpulseLink() {
      try {
        await axios.put(
          `${useRuntimeConfig().firebaseDbLink}data/projects/safety/${
            this.editedProject.exid
          }.json`,
          { link: this.tPulseLink }
        )
        useNuxtApp().nuxt2Context.$notifications.success(
          "T-pulse link updated successfully"
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: "Failed to update T-pulse link!",
          error,
        })
      }
    },
    async fetchProjectTPulseLink(projectExid) {
      this.tPulseLink = null
      const { data } = await axios.get(
        `${
          useRuntimeConfig().firebaseDbLink
        }data/projects/safety/${projectExid}.json`
      )
      this.tPulseLink = data?.link
    },
  },
})
