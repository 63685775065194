import { defineStore } from "pinia"
import { AiApi } from "@evercam/shared/api/aiApi"
import { GateReportROI } from "@evercam/shared/types"
import { useNuxtApp } from "#app"

type RoiShape = {
  id: number | null
  name: string
  x1: number
  x2: number
  y1: number
  y2: number
}
export enum RoiType {
  Gate = "gate",
  AnprContext = "anpr_context",
  ExclusionZone = "exclusion_zone",
  WorkflowArea = "workflow_area",
}

type RoiState = {
  snapshotUrl: string
  ROI: GateReportROI
  ROIList: GateReportROI[]
  defaultROI: {
    rectangle: RoiShape
    line: RoiShape
    direction: RoiShape
  }
}

export const useRoiStore = defineStore({
  id: "roi",
  state: (): RoiState => ({
    snapshotUrl: "",
    ROI: {},
    ROIList: [],
    defaultROI: {
      rectangle: {
        id: null,
        name: "",
        x1: 0.1,
        x2: 0.2,
        y1: 0.1,
        y2: 0.2,
      },
      line: {
        id: null,
        name: "",
        x1: 0.11,
        x2: 0.19,
        y1: 0.15,
        y2: 0.15,
      },
      direction: {
        id: null,
        name: "",
        x1: 0.11,
        x2: 0.19,
        y1: 0.11,
        y2: 0.19,
      },
    },
  }),
  actions: {
    async fetchROIs({ projectExid, ...payload }) {
      const rawCoords = await AiApi.roi.getROIs(projectExid, payload)
      this.ROIList = rawCoords.reduce(
        (
          acc,
          { id, cameraex, fromDate, name, shapes, roiType, directionFilter }
        ) => {
          const shapesObjects = shapes.reduce(
            (_acc, { type, coordinates: [[x1, y1], [x2, y2]] }) => {
              return {
                ..._acc,
                [type]: {
                  id,
                  roiType,
                  directionFilter,
                  cameraex,
                  name,
                  x1: Number.parseFloat(x1 as unknown as string) || 0,
                  x2: Number.parseFloat(x2 as unknown as string) || 0,
                  y1: Number.parseFloat(y1 as unknown as string) || 0,
                  y2: Number.parseFloat(y2 as unknown as string) || 0,
                },
              }
            },
            {}
          )

          return {
            ...acc,
            [fromDate]: {
              ...shapesObjects,
            },
          }
        },
        {}
      )
    },
    async upsertROI({ id, cameraId, date, ROI, note, userEmail }) {
      try {
        let shapes = []
        const shapeTypes = ["rectangle", "direction", "line"]
        const coords = ["x1", "y1", "x2", "y2"]

        const isValidShape = (shapeProps) =>
          shapeProps && coords.every((coord) => shapeProps[coord])

        const createShape = (type, shapeProps) => ({
          type,
          coordinates: [
            [shapeProps.x1, shapeProps.y1],
            [shapeProps.x2, shapeProps.y2],
          ],
        })

        shapeTypes.forEach((type) => {
          const shapeProps = ROI?.[type]
          if (isValidShape(shapeProps)) {
            shapes = [...shapes, createShape(type, shapeProps)]
          }
        })

        const isCreation = isNaN(parseInt(id))
        let upsertROI = isCreation ? AiApi.roi.createROI : AiApi.roi.updateROI

        await upsertROI({
          [isCreation ? "createdBy" : "updatedBy"]: userEmail,
          roi: {
            id,
            shapes,
            fromDate: date,
            cameraex: cameraId,
            name: ROI.rectangle.name || `No name (${date})`,
            roiType: ROI.rectangle.roiType || "gate",
            directionFilter: ROI.rectangle.directionFilter || "left,arrived",
            note,
            isactive: true,
          },
        })
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: `Couldn't save ROI coordinates on date ${date}!`,
          error,
        })
      }
    },
    updateROIList({ date, change }) {
      if (!this.ROIList[date]) {
        this.ROIList[date] = this.defaultROI
      }

      this.ROIList[date] = {
        rectangle: {
          name: `No name ${date}`,
          ...this.defaultROI.rectangle,
          ...this.ROIList[date].rectangle,
          ...change.rectangle,
        },
        line: {
          name: `No name ${date}`,
          ...this.defaultROI.line,
          ...this.ROIList[date].line,
          ...change.line,
        },
        direction: {
          name: `No name ${date}`,
          ...this.defaultROI.direction,
          ...this.ROIList[date].direction,
          ...change.direction,
        },
      }
    },
    updateROI(newROI) {
      if (!this.ROI) {
        this.ROI = this.defaultROI
      }
      this.ROI = {
        rectangle: {
          ...this.ROI.rectangle,
          ...newROI.rectangle,
        },
        line: {
          ...this.ROI.line,
          ...newROI.line,
        },
        direction: {
          ...this.ROI.direction,
          ...newROI.direction,
        },
      }
    },
  },
})
