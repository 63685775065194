import Vue from "vue"
import {
  toQueryString,
  getQueryParams,
  queryStringToObject,
  getSubQueryString,
  getSubQueryParams,
  clearQuery,
  clearSubQuery,
  updateSubQuery,
  updateQuery,
  allowKeypress,
  getFormattedDatesDiff,
  snakeCaseToTitleCase,
} from "@evercam/shared/utils"

if (!Vue.__shared_mixin___) {
  // See https://nuxtjs.org/docs/directory-structure/plugins/#global-mixins
  Vue.__shared_mixin___ = true

  Vue.mixin({
    methods: {
      getFormattedDatesDiff,
      allowKeypress,
      updatePageURL(queryString, defaultParams, forceUpdate = false) {
        const route = this.$route.path
        let params = this.queryStringToObject(queryString)
        const cleanedQuery = this.toQueryString(params)
        const subQuery = this.getSubQueryString()
        if (cleanedQuery === defaultParams && !forceUpdate) {
          history.replaceState(
            {},
            null,
            `${route}${subQuery ? "#" : ""}${subQuery}`
          )
        } else {
          history.replaceState(
            {},
            null,
            `${route}?${cleanedQuery}${subQuery ? "#" : ""}${subQuery}`
          )
        }
      },
      toQueryString: (params) =>
        toQueryString(params, { forceSnakeCase: false }),
      queryStringToObject: (queryString) => queryStringToObject(queryString),
      getQueryParams,
      getSubQueryString,
      getSubQueryParams,
      updateQuery: (queryObj = {}) => updateQuery(queryObj),
      updateSubQuery: (subQueryObj) => updateSubQuery(subQueryObj),
      clearQuery,
      clearSubQuery,
      snakeCaseToTitleCase,
      formatDate(date, format = "DD MMM YYYY HH:mm:ss") {
        return this.$moment(date).isValid()
          ? this.$moment(date).format(format)
          : "-"
      },
      relativeDate(date) {
        return this.$moment(date).isValid() ? this.$moment(date).fromNow() : "-"
      },
    },
  })
}
