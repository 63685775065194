<template>
  <v-app>
    <TheAppBar />
    <TheSnackbar />
    <TheSidebar />
    <ConfirmDialog />
    <CameraDialog
      v-if="cameraDialogStore.camera && cameraDialogStore.dialog"
      :camera="cameraDialogStore.camera"
      :inline="cameraDialogStore.isInline"
    />
    <ProjectDialog
      v-if="projectStore.selectedProjectExid && projectStore.isDialogOpen"
      :project-exid="projectStore.selectedProjectExid"
      :show-dialog="projectStore.isDialogOpen"
      @close-dialog="projectStore.closeDialog"
    />
    <UserDialog v-if="userStore.dialog" />
    <v-main>
      <div class="d-flex align-center">
        <TheBreadcrumbs
          v-if="breadcrumbStore.breadcrumbs.length"
          :items="breadcrumbStore.breadcrumbs"
        />
        <div class="ml-auto">
          <portal-target name="breadcrumbs-right"></portal-target>
        </div>
      </div>
      <v-divider v-if="breadcrumbStore.breadcrumbs.length" />
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
import TheAppBar from "@/components/TheAppBar"
import TheSnackbar from "@/components/TheSnackbar"
import TheSidebar from "@/components/TheSidebar"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import ConfirmDialog from "@evercam/shared/components/ConfirmDialog"
import ProjectDialog from "@/components/projects/ProjectDialog"
import CameraDialog from "@/components/cameraDialog"
import UserDialog from "@/components/users/UserDialog"
import { camelizeKeys } from "humps"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"
import { useUserStore } from "@/stores/users"
import { useBreadcrumbStore } from "@/stores/breadcrumb"

export default {
  components: {
    TheAppBar,
    TheSnackbar,
    TheSidebar,
    TheBreadcrumbs,
    ConfirmDialog,
    ProjectDialog,
    CameraDialog,
    UserDialog,
  },
  middleware: ["auth", "reportsAccess", "breadcrumbs"],
  head() {
    return {
      htmlAttrs: {
        class: "overflow-y-auto",
      },
    }
  },
  computed: {
    ...mapStores(
      useCameraDialogStore,
      useProjectStore,
      useBreadcrumbStore,
      useUserStore
    ),
  },
  created() {
    this.initDialogs()
  },
  methods: {
    initDialogs() {
      const { projectDialog, projectExid, dialog, exid, userDialog, userId } =
        camelizeKeys(this.getSubQueryParams())

      if (userDialog && userId) {
        this.userStore.openDialog(userId)
      } else if (projectDialog && projectExid) {
        this.projectStore.openDialog(projectExid)
      } else if (dialog && exid) {
        this.cameraDialogStore.openDialog({ camera: exid })
      }
    },
  },
}
</script>

<style lang="scss">
.v-main__wrap {
  overflow: hidden;
}
</style>
