import { defineStore } from "pinia"
import {
  updateSubQuery,
  clearSubQuery,
  QueryParams,
} from "@evercam/shared/utils"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useProjectStore } from "@/stores/projects"
import { decamelizeKeys } from "humps"
import { AdminUser } from "@evercam/shared/types"
import { useNuxtApp } from "#app"

interface UserState {
  dialog: boolean
  isLoading: boolean
  userDetails: AdminUser
  currentTab: number
  passwordResetLink: string
}

export const useUserStore = defineStore({
  id: "user",
  state: (): UserState => ({
    dialog: false,
    isLoading: false,
    userDetails: {} as AdminUser,
    currentTab: 0,
    passwordResetLink: null,
  }),
  actions: {
    openDialog(userId) {
      useCameraDialogStore().dialog = false
      useProjectStore().isDialogOpen = false

      updateSubQuery(
        decamelizeKeys({ userDialog: true, userId }) as QueryParams
      )
      this.dialog = true
      this.fetchUserDetails(userId)
    },
    closeDialog() {
      clearSubQuery()
      this.$reset()
    },
    async fetchUserDetails(userId) {
      const { query } = useNuxtApp().nuxt2Context.route
      try {
        this.isLoading = true
        if (userId?.includes("@")) {
          const { items } = await AdminApi.users.getUsers({
            params: { email: userId },
          })
          userId = items[0]?.id
        }
        const { data } = await AdminApi.pa.getPAUser(userId, query)
        this.userDetails = data?.userStats || {}
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    async getPasswordResetLink(email) {
      await AdminApi.users
        .getUserPasswordResetLink(email)
        .then((res) => {
          this.passwordResetLink = res.passwordResetLink
        })
        .catch((error) => {
          console.log(error)
          useNuxtApp().nuxt2Context.$notifications.error({
            text: "Failed to generate password reset link!",
            error,
          })
        })
    },
  },
})
