import { CAMERA_STATUSES_IDS_CLASSES } from "@/components/constants.js"

export default {
  name: "CameraUtils",
  methods: {
    getStatusColor(status) {
      return CAMERA_STATUSES_IDS_CLASSES[status]?.class || ""
    },
    getStatusText(status) {
      return CAMERA_STATUSES_IDS_CLASSES[status]?.value || status
    },
  },
}
